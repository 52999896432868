/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 30/3/2021
 * @Example
 */

import { Collapse, Typography } from "diginet-core-ui/components";
import { ArrowUp } from 'diginet-core-ui/icons';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Config from "../../../../../config";
import W39F3000Goal from './W39F3000Goal';

W39F3000GroupGoal.propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    colorStatusArr: PropTypes.array,
    redirect: PropTypes.func,
    changeLoading: PropTypes.func,
};

function W39F3000GroupGoal(props) {
    const { data, classes, colorStatusArr, redirect, changeLoading, toggleRefs } = props;
    const [expand, setExpand] = useState(true);

    const listGoalRef = useRef(null);

    let position = {
        left: 0,
        x: 0,
    };

    const toogleExpand = () => {
        setExpand(!expand);
    };

    const totalProportion = data.GroupGoalDetail.reduce((total, current) => total + current.Proportion, 0);
    const totalResult = data.GroupGoalDetail.reduce((total, current) => total + (current.Result * current.Proportion / totalProportion), 0).toFixed(2);

    const onMouseDown = e => {
        position = {
            left: listGoalRef.current.scrollLeft,
            x: e.clientX,
        };

        if (listGoalRef.current.scrollWidth > listGoalRef.current.clientWidth) {
            listGoalRef.current.addEventListener('mousemove', onMouseMove);
            listGoalRef.current.addEventListener('mouseup', onMouseUp);
        }
    };

    const onMouseMove = e => {
        const dx = e.clientX - position.x;

        // Scroll the element
        if (listGoalRef.current) {
            listGoalRef.current.style.cursor = 'grabbing';
            listGoalRef.current.style.userSelect = 'none';
            listGoalRef.current.scrollLeft = position.left - dx;
        }
    };

    const onMouseUp = () => {
        listGoalRef.current.style.cursor = null;
        listGoalRef.current.style.removeProperty('user-select');
        listGoalRef.current.removeEventListener('mousemove', onMouseMove);
        listGoalRef.current.removeEventListener('mouseup', onMouseUp);
    };

    return (
        <div>
            <div className={classes.divGroupGoalName} style={{ borderColor: data.Color }}>
                <span
                    onClick={toogleExpand}
                    className={`${classes.iconExpandTransition} ${classes.pointerCursor}`}
                    style={{ color: data.Color, transform: expand ? "" : "rotate(180deg)" }}
                >
                    <ArrowUp width={24} height={24} viewBox color={"currentColor"} />
                </span>
                <Typography type={"h3"} color={data?.Color || ""}>
                    {`${data.GroupGoalName || ""} (${data.GroupGoalDetail.length})`}
                </Typography>
            </div>
            <Collapse open={expand}>
                <div
                    className={`${classes.divListGoalCont} ${classes.flexGap10}`}
                    onMouseDown={onMouseDown}
                    ref={listGoalRef}
                >
                    {data?.GroupGoalDetail?.length > 0 && (
                        <div className={`${classes.gridGoal} ${classes.flexGap10}`}>
                            <div className={classes.divGoalName}>
                                <Typography type={"p2"} fullWidth={false}>
                                    {Config.lang("Ten_muc_tieu")}
                                </Typography>
                            </div>
                            <div
                                className={classes.divOtherGoalField}
                                style={{ width: 120, display: "flex", flexDirection: "column", alignItems: "center" }}
                            >
                                <Typography type={"p2"} fullWidth={false}>
                                    {Config.lang("Ket_qua")}
                                </Typography>
                                <Typography type={"p2"} fullWidth={false}>
                                    ({totalResult})
                                </Typography>
                            </div>
                            <div className={classes.divOtherGoalField} style={{ width: 80 }}>
                                <Typography type={"p2"}>{Config.lang("Trong_so")}</Typography>
                                <Typography type={"p2"}>({totalProportion})</Typography>
                            </div>
                            <div className={classes.divOtherGoalField} style={{ width: 120 }}>
                                <Typography type={"p2"}>{Config.lang("Han_hoan_thanh")}</Typography>
                            </div>
                            <div className={classes.divOtherGoalField} style={{ width: 140 }}>
                                <Typography type={"p2"}>{Config.lang("Thuc_hien1")}</Typography>
                            </div>
                        </div>
                    )}
                    {data.GroupGoalDetail.map((i, index) => (
                        <W39F3000Goal
                            key={i.GoalID}
                            data={i}
                            colorStatusArr={colorStatusArr}
                            classes={classes}
                            redirect={redirect}
                            toggleRefs={toggleRefs}
                            changeLoading={changeLoading}
                        />
                    ))}
                </div>
            </Collapse>
        </div>
    );
}

export default W39F3000GroupGoal;