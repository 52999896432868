/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 1/7/2021
 * @Example
 */

import { Collapse, Button, Status, Typography, Tooltip } from 'diginet-core-ui/components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../../../config';
import * as W39F3000Actions from '../../../../../redux/W3X/W39F3000/W39F3000_actions';
import W39F3000GroupGoal from './W39F3000GroupGoal';
import { usePlanStyle } from '../planStyle';
import Icon from 'diginet-core-ui/icons';
import W84F3010 from "../../../../W8X/W84/W84F3010/W84F3010.js";
const W39F3000GoalPlan = (props) => {
    const {
        classes,
        colorStatusArr,
        redirect,
        changeLoading,
        loadData,
        data,
        selectedGoalPlan,
        changeSelectedGoalPlan,
        toggleRefs,
    } = props;

    const [expand, setExpand] = useState(false);
    const [showW84F3010Popup, setShowW84F3010Popup] = useState(false);

    const planStyle = usePlanStyle({ expand });

    useEffect(() => {
        setExpand(data?.GoalPlanID === selectedGoalPlan);
    }, [data, selectedGoalPlan]);

    const detailData = useSelector((state) => state.W39F3000.detailData) ?? {};

    const dispatch = useDispatch();

    const finishGoalPlan = () => {
        const param = {
            DivisionID: Config.getDivisionID() || '',
            FormID: 'W39F3000',
            Language: Config.language || '84',
            UserID: Config.profile.UserID || '',
            TransID: data.TransID || '',
            Mode: data?.StatusID === 0 ? 0 : 1,
        };

        changeLoading(true);
        dispatch(
            W39F3000Actions.finishGoalPlan(param, (err, dt) => {
                changeLoading(false);
                if (err) return Config.popup2.show('ERROR', err);
                if (dt?.Status) return Config.notify.show('error', dt?.Message ?? '', 2000);
                loadData();
            })
        );
    };

    const handleExpand = (newGoalPlan = '') => {
        if (expand) {
            changeSelectedGoalPlan();
        } else {
            changeSelectedGoalPlan(newGoalPlan);
        }
        setExpand((expand) => !expand);
    };

    const  onHistoryApproval = () => {
        setShowW84F3010Popup(true);
    };

    const saveApproval = () => {
        const params = {
            ModuleID: "D39",
            VoucherID: data?.TransID ?? "",
            EmployeeID: data?.EmployeeID ?? "",
            FormID: "W39F3000",
            TransTypeID: "",
            Mode: data?.StatusID === 0 ? 0 : 1,
            StatusApproval: 0,
            ApprovalLevel: 0,
            Language: Config.language || 84,
            TransactionID: "W39F3001",
            Note: "",
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_dang_ky_muc_tieu_khong?"), () => {
            dispatch(
                W39F3000Actions.saveStandardApproval(params, (errors, data) => {
                    if (errors) {
                        Config.popup.show("ERROR", errors);
                        return false;
                    }
                    if(data?.Status === 0) {
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                        loadData();
                    } else {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                    }
                })
            );
        });
    };

    // set toggleRefs = [] khi mở GoalPlan mới
    const clearToggleRefs = () =>{
        toggleRefs.current = []
    }

    return (
        <div id={data?.GoalPlanID ?? ''} className={`${planStyle.divGoalPlanCont}`}>
            {showW84F3010Popup ?
                <W84F3010
                    open={showW84F3010Popup}
                    onClose={() => setShowW84F3010Popup(false)}
                    FormID="W39F3000"
                    TransID={data?.TransID} />
                : ""}

            <div className={"display_row"}>
                <div className={`${planStyle.divGoalPlanName}`}>
                    <Typography type={'h3'} className={`${classes.goalPlanName} ${classes.pointerCursor}`} onClick={() => handleExpand(data?.GoalPlanID ?? '')}>
                        {data?.GoalPlanName ?? ''}
                    </Typography>
                    {Number.isInteger(data?.StatusID) && (
                        <Status color={'#0095FF'} text={data?.StatusName ?? ''}
                                viewType="filled" style={{ marginLeft: 10 }}
                        />
                    )}
                </div>
                {
                    data?.IsGoalApproval === 1 &&
                    (data?.GoalAppStatus === 1 ||
                        data?.GoalAppStatus === 2 ||
                        data?.GoalAppStatus === 100
                    ) ?
                        <Tooltip title={Config.lang("Lich_su_duyet")}>
                            <Icon
                                name={"history"}
                                className={"mgr15"}
                                onClick={() => {
                                    onHistoryApproval()
                                }}
                            />
                        </Tooltip>
                        : ""}
            </div>
            {expand ?
                <div className={"display_row align-center"}>
                    {
                        data?.IsGoalApproval === 1 &&
                        (data?.GoalAppStatus === 0 ||
                            data?.GoalAppStatus === 100
                        )  ?
                            <Button
                                color={'primary'}
                                size={"medium"}
                                text={Config.lang("Dang_ky_muc_tieu")}
                                viewType="filled"
                                onClick={saveApproval}
                            />
                             : ""}


                </div>
                : ""}
            <Collapse onOpened={clearToggleRefs} open={expand}>
                {data?.GoalPlanID === selectedGoalPlan &&
                    detailData?.GroupGoal?.map(i => {
                        return (
                            <W39F3000GroupGoal
                                key={i.GroupGoalID}
                                data={i}
                                classes={classes}
                                colorStatusArr={colorStatusArr}
                                redirect={redirect}
                                changeLoading={changeLoading}
                                toggleRefs={toggleRefs}
                            />
                        );
                    })}
            </Collapse>
            {data?.GoalPlanID === selectedGoalPlan && !detailData?.IsReview && !detailData?.IsApprove && (
                <Button
                    color={'info'}
                    text={data?.StatusID ? Config.lang('Huy_hoan_tat') : Config.lang('Hoan_tat')}
                    viewType="filled"
                    className={`${classes.btnFinish} W39F3000-Finish-Button`}
                    style={{ float: 'right', marginTop: 10 }}
                    onClick={finishGoalPlan}
                    disabled={data?.GoalAppStatus === 1|| data?.GoalAppStatus === 2}
                />
            )}
        </div>
    );
};

W39F3000GoalPlan.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    colorStatusArr: PropTypes.array,
    redirect: PropTypes.func,
    changeLoading: PropTypes.func,
    loadData: PropTypes.func,
    selectedGoalPlan: PropTypes.string,
    changeSelectedGoalPlan: PropTypes.func,
};

W39F3000GoalPlan.defaultProps = {
    classes: {},
    data: {},
    colorStatusArr: [],
    redirect: null,
    changeLoading: null,
    loadData: null,
    selectedGoalPlan: '',
    changeSelectedGoalPlan: null,
};

export default W39F3000GoalPlan;
