import React, { Component } from "react";
import { makeStyles, useTheme } from "diginet-core-ui/theme";
import { Grid } from "@material-ui/core";
import { Row, Col, FormGroup, Image } from "react-bootstrap";
import InlineSVG from "react-inlinesvg";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import DOMPurify from "dompurify";
import moment from "moment";
import _ from "lodash";
import Filter from "../../../filter/filter";
import FileViewer from "../../../common/fileviewer/fileviewer";
import { Modal, ModalHeader, ModalBody, TextInput } from 'diginet-core-ui/components';
import Approvals from "../../../approvals/approvals";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import { Button, ButtonIcon, DatePicker, Dropdown, Label, Tooltip, Paper, Typography, Divider } from 'diginet-core-ui/components';
import W39F2035 from "../W39F2035/W39F2035";

const useStyles = makeStyles((theme) => ({
    flexRow: {
        width: "99%",
        display: "flex",
    },
    imgAvatar: {
        width: '100%',
        height: '100%',
        objectFit: "contain",
    },
    gridVertical: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        border: `1px solid ${theme?.boxShadows?.large}`,
        borderRadius: theme?.border?.radius,
        backgroundColor: "#F0F0F3",
        color: "black",
        "& > .MuiTypography-root": {
            margin: theme?.spacing(1.5),
        }
    },
    headerTitle: {
        margin: "auto"
    },
    contentText: {
        whiteSpace: "pre-wrap",
        marginTop: 16,
        paddingBottom: 16,
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        position: 'relative',
        minHeight: 165,
        border: '1px solid #687799',
        paddingBottom: 20,
        width: '100%'
    },
    attachToolbar: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        height: 48,
        padding: 10,
        zIndex: 100,
        marginBottom: 10
    },
    attachItem: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 0 5px #00000034',
        borderRadius: 5,
        overflow: 'hidden',
        cursor: 'pointer',
        position: 'relative',
        '& .att-wrap-icon': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '60%'
        },
        '& .att-backdrop-item': {
            display: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0.4,
            backgroundColor: '#484848'
        },
        '& .att-info-item': {
            display: 'none',
            position: 'absolute',
            color: '#FFFFFF',
            top: 0,
            left: 0,
            padding: 10,
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '& .att-item-name': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
        },
        '& .att-item-detail': {
            display: 'none',
            position: 'absolute',
            bottom: 10,
            left: 0,
            width: '100%',
            padding: '0 10px'
        },
        '&:hover': {
            '& .att-backdrop-item, .att-info-item, .att-item-detail': {
                display: 'flex'
            }
        }
    },
    titleAttachment: {
        fontWeight: 400,
        fontSize: "1.1rem",
        padding: "10px 0",
        textAlign: "center",
        borderBottom: "2px solid #cfd4db",
        color: theme?.colors?.primary,
    },
    rightTitle: {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
    },
    note: {
        width: "90%",
        whiteSpace: "pre-wrap",
        fontStyle: 'italic !important',
    },
    imageRounded: {
        height: 65,
        width: 65,
        "& > img": {
            objectFit: "cover",
            height: 65,
            width: 65,
        },
    },
    muiFormLabelRootGeneral: {
        color: `${theme?.colors?.text?.normal_label} !important`,
        lineHeight: `${theme?.typography?.label?.lineHeight} !important`,
        fontFamily: `${theme?.typography?.label?.fontFamily} !important`,
    },
    pointer: {
        cursor: 'pointer'
    },
    innerHTML: {
        fontWeight: 400,
        fontSize: '1.1rem'
    },
    proportion: {
        padding: 0,
        height: 25,
        lineHeight: '25px !important',
        backgroundColor: '#CDCDCD',
        textAlign: 'center'
    }
}));
class W39F2030 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            loading: false,
            gridLoading: false,
            cboEmployeeLoading: false,
            showZoomFormInfo: false,
            showFileViewer: false,
            openModalW39F2030: false,
            VoucherID: "",
            DateFrom: null,
            DateTo: null,
            fileOpened: {},
            dataCboEmployees: { rows: [], total: 0 },
            ApprovalStatus: "",
            TransTypeID: "",
            EmployeeID: "",
        };
        this.defaultParams = {
            DivisionID: Config.getDivisionID(),
            FormID: "W39F2030",
            Language: Config.language || "84",
        };
        this.filter = {
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            search: "",
            skip: 0,
            limit: 50,
        };
    }

    handleChangeIndex = (index) => {
        this.setState({
            index,
        });
    };

    handleFilter = (key, e) => {
        if (!e) return;
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        switch (key) {
            case "DateTo":
            case "DateFrom":
            case "EmployeeID":
            case "TransTypeID":
            case "ApprovalStatus":
                this.setState({ [key]: value });
                break;
            default:
                break;
        }
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W39F2030", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadGrid = (VoucherID) => {
        const { voucher_id } = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : (voucher_id ? voucher_id : "");
        const { DateFrom, DateTo, EmployeeID, ApprovalStatus, TransTypeID } = this.state;
        const params = {
            ...this.defaultParams,
            DateFrom,
            DateTo,
            ApprovalStatus,
            TransTypeID,
            EmployeeID,
            VoucherID
        };
        this.setState({ gridLoading: true });
        this.props.approvalActions.getGridApprovals(params, (err) => {
            this.setState({ gridLoading: false });
            if (err) {
                Config.popup2.show("INFO", _.get(err, "message", "") || Config.lang("Loi_chua_xac_dinh"));
                return false;
            }
        });
    };

    loadCboEmployees = (isReset = false) => {
        const { skip, limit, strSearch } = this.filterCboEmployees;
        const param = {
            Type: "EmployeeID",
            ...this.defaultParams,
            skip,
            limit,
            search: strSearch,
        };
        this.setState({ cboEmployeeLoading: true });
        this.props.generalActions.getCboEmployees(param, (err, data) => {
            this.setState({ cboEmployeeLoading: false });
            if (err) {
                Config.popup2.show("INFO", _.get(err, "message", "") || Config.lang("Loi_chua_xac_dinh"));
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language: Config.language || "84",
        };
        this.props.approvalActions.getCboStatus(params, (err) => {
            if (err) return Config.popup2.show("INFO", _.get(err, "message", "") || Config.lang("Loi_chua_xac_dinh"));
        });
    };

    loadCboTransType = () => {
        this.props.approvalActions.getCboApprovalTransType((err) => {
            if (err) return Config.popup2.show("INFO", _.get(err, "message", "") || Config.lang("Loi_chua_xac_dinh"));
        });
    };

    loadFormInfo = (VoucherID) => {
        const params = {
            ...this.defaultParams,
            VoucherID: VoucherID || "",
        };
        this.setState({ loading: true });
        this.props.approvalActions.getFormInfo(params, (err, data) => {
            this.setState({ loading: false });
            if (err) return Config.popup2.show("INFO", _.get(err, "message", "") || Config.lang("Loi_chua_xac_dinh"));
            if (data) {
                this.setState({
                    dataForm: data
                });
            }
        });
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({ VoucherID: "" });
    };

    onCloseViewFile = () => {
        this.setState({ showFileViewer: false });
    };

    onViewFile = (data) => {
        this.setState({
            fileOpened: {
                FileName: data.FileName ? data.FileName : "",
                FileExt: data.FileExt ? data.FileExt : "",
                URL: data.URL ? Config.env.cdn + "/" + data.URL : "",
            },
            showFileViewer: true,
        });
        this.onDrawerClosed();
    };

    renderFilter = () => {
        const { getCboStatus = [], getCboApprovalTransType = [] } = this.props;
        const { dataCboEmployees, cboEmployeeLoading, DateTo, DateFrom, EmployeeID, ApprovalStatus, TransTypeID } = this.state;
        return (
            <Filter
                isUseDDCore={true}
                showHeader={false}
                openOnFieldClick={true}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label style={{ paddingBottom: 15, textOverflow: 'initial' }} className={"mgr5"}>{Config.lang("Tu")}</Label>
                                            <DatePicker
                                                clearAble
                                                viewType={"outlined"}
                                                placeholder={"dd/mm/yyyy"}
                                                displayFormat={"DD/MM/YYYY"}
                                                max={DateTo}
                                                value={DateFrom}
                                                onChange={e => this.handleFilter("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label style={{ paddingBottom: 15, textOverflow: 'initial' }} className={"mgr5"}>{Config.lang("Den")}</Label>
                                            <DatePicker
                                                clearAble
                                                min={DateFrom}
                                                value={DateTo}
                                                viewType={"outlined"}
                                                placeholder={"dd/mm/yyyy"}
                                                displayFormat={"DD/MM/YYYY"}
                                                onChange={e => this.handleFilter("DateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"ApprovalStatus"}
                                            displayExpr={"AppStatusName"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Trang_thai_duyet")}
                                            dataSource={getCboStatus}
                                            value={ApprovalStatus}
                                            onChange={(e) => this.handleFilter("ApprovalStatus", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"TransTypeID"}
                                            displayExpr={"TransTypeName"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Loai_danh_gia")}
                                            value={TransTypeID}
                                            dataSource={getCboApprovalTransType}
                                            onChange={(e) => this.handleFilter("TransTypeID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={_.get(dataCboEmployees, "rows", [])}
                                            total={_.get(dataCboEmployees, "total", 0)}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            loading={cboEmployeeLoading}
                                            value={EmployeeID}
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            displayExpr={"EmployeeName"}
                                            label={Config.lang("Nguoi_lap")}
                                            placeholder={Config.lang("Chon")}
                                            onChange={e => this.handleFilter("EmployeeID", e)}
                                            onInput={(e) => {
                                                if (this.filterCboEmployees.timer) clearTimeout(this.filterCboEmployees.timer);
                                                this.filterCboEmployees.timer = setTimeout(() => {
                                                    this.filterCboEmployees.strSearch = e.target.value;
                                                    this.filterCboEmployees.skip = 0;
                                                    this.filterCboEmployees.limit = 50;
                                                    this.loadCboEmployees(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    startIcon={"Search"}
                                    label={Config.lang("Tim_kiem")}
                                    onClick={() => this.loadGrid()}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    convertNumberToRoman = (num) => {
        if (typeof num !== "number") return;
        let digits = String(+num).split(""),
            key = [
                "",
                "C",
                "CC",
                "CCC",
                "CD",
                "D",
                "DC",
                "DCC",
                "DCCC",
                "CM",
                "",
                "X",
                "XX",
                "XXX",
                "XL",
                "L",
                "LX",
                "LXX",
                "LXXX",
                "XC",
                "",
                "I",
                "II",
                "III",
                "IV",
                "V",
                "VI",
                "VII",
                "VIII",
                "IX",
            ],
            romanNum = "",
            i = 3;
        while (i--) romanNum = (key[+digits.pop() + i * 10] || "") + romanNum;
        return Array(+digits.join("") + 1).join("M") + romanNum;
    };

    getIconFile = (fileName) => {
        let icon = require("../../../../assets/images/files/file.svg");
        try {
            icon = require("../../../../assets/images/files/" + fileName);
        } catch (e) {
        }
        return icon;
    };

    getStringNameResult = (resStrNumber) => {
        let resStrName = "";
        switch (resStrNumber) {
            case "01":
                resStrName = Config.lang("Dat");
                break;
            case "02":
                resStrName = Config.lang("Khong_dat");
                break;
            default:
                break;
        }
        return resStrName;
    };

    renderFormInfo = (data, isModal = false) => {
        const classes = useStyles();
        const coreTheme = useTheme();
        const { spacing, colors: { text: { normal_label } } } = coreTheme || {};
        const { getAttachments } = this.props;
        const getUser = Config.getUser({ EmployeeID: _.get(data, "EmployeeID") });
        const getAppCriterionGroup = _.get(data, "AppCriterionGroup", "");
        const getEvaluationElement = _.get(data, "EvaluationElement", "");
        const numEvaluationLevel = _.toNumber(_.get(data, "NumEvaluationLevel", 0));
        const isEvaluateContract = _.get(data, "IsEvaluateContract", 0);
        const numEvaluationLevel2 = numEvaluationLevel >= 2;
        const numEvaluationLevel3 = numEvaluationLevel >= 3;
        const numEvaluationLevel4 = numEvaluationLevel >= 4;
        const numEvaluationLevel5 = numEvaluationLevel === 5;
        let sizeCol = 12;
        switch (numEvaluationLevel) {
            case 2:
                sizeCol = 6;
                break;
            case 3:
                sizeCol = 4;
                break;
            case 4:
            case 5:
                sizeCol = 3;
                break;
            default:
                break;
        }
        if (_.isEmpty(getAppCriterionGroup) || _.isEmpty(getEvaluationElement)) return "";
        const arrListOfCriteria = getAppCriterionGroup
            .map((itemGr) => ({
                ...itemGr,
                dataEvaluationElm: getEvaluationElement.filter((itemElm) => itemGr.AppCriterionGroupID === itemElm.AppCriterionGroupID),
            }))
            .filter((item) => item.dataEvaluationElm.length > 0);
        const arrEmployeeInfo = getUser
            ? [
                {
                    content: _.compact([getUser.EmployeeID, getUser.EmployeeName]).join(" - "),
                    isVisible: _.get(getUser, "EmployeeName", "") || _.get(getUser, "EmployeeID", ""),
                },
                {
                    content: _.compact([getUser.DutyName, getUser.DepartmentName]).join(" - "),
                    isVisible: _.get(getUser, "DutyName", "") || _.get(getUser, "DepartmentName", ""),
                },
                {
                    content: moment.utc(data.AppraisalDate).format("DD/MM/YYYY kk:mm:ss"),
                    isVisible: moment(_.get(_.get(data, "AppraisalDate", ""))).isValid(),
                },
                {
                    content: `${Config.lang("Danh_gia_hop_dong_thu_viec")}: ${data.WorkFormName} `,
                    isVisible: _.get(data, "IsEvaluateContract", false) && _.get(data, "WorkFormName", ""),
                },
            ].filter((item) => Boolean(item.isVisible))
            : [];
        const arrLabelLevels = [
            {
                label: _.get(data, "LabelLevel1", ""),
                note: _.get(data, "NoteEmployee", ""),
                isVisible: true
            },
            {
                label: _.get(data, "LabelLevel2", ""),
                note: _.get(data, "NoteEvaluatedLevel2", ""),
                isVisible: numEvaluationLevel2
            },
            {
                label: _.get(data, "LabelLevel3", ""),
                note: _.get(data, "NoteEvaluatedLevel3", ""),
                isVisible: numEvaluationLevel3
            },
            {
                label: _.get(data, "LabelLevel4", ""),
                note: _.get(data, "NoteEvaluatedLevel4", ""),
                isVisible: numEvaluationLevel4
            },
            {
                label: _.get(data, "LabelLevel5", ""),
                note: _.get(data, "NoteEvaluatedLevel5", ""),
                isVisible: numEvaluationLevel5
            }
        ].filter((item) => Boolean(item.isVisible));
        const arrResultString = [
            { result: this.getStringNameResult(_.get(data, "ResultID1", "")), isVisible: true },
            { result: this.getStringNameResult(_.get(data, "ResultID2", "")), isVisible: numEvaluationLevel2 },
            { result: this.getStringNameResult(_.get(data, "ResultID3", "")), isVisible: numEvaluationLevel3 },
            { result: this.getStringNameResult(_.get(data, "ResultID4", "")), isVisible: numEvaluationLevel4 },
            { result: this.getStringNameResult(_.get(data, "ResultID5", "")), isVisible: numEvaluationLevel5 }
        ].filter((item) => Boolean(item.isVisible));
        return (
            <>
                <FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <div className={classes.flexRow} style={{ justifyContent: "flex-end", marginTop: 8 }}>
                                    <Typography type={"title4"} color={normal_label} uppercase className={`${classes.headerTitle}`}>
                                        {_.get(data, "TransTypeName", "")}
                                    </Typography>
                                    {!isModal ? (
                                        <ButtonIcon
                                            circular
                                            name={"ZoomOutMap"}
                                            size={"medium"}
                                            viewType={"text"}
                                            onClick={() => this.setState({ showZoomFormInfo: true })}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Typography
                                    type={"p3"}
                                    style={{
                                        margin: Config.isMobile ? 0 : "auto",
                                        textAlign: Config.isMobile ? "left" : "center"
                                    }}
                                    className={`${classes.note} `}
                                >  {_.get(data, "Note", "")}</Typography>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className={classes.imageRounded}>
                                        {_.get(getUser, "UserPictureURL", "") ? (
                                            <Image
                                                style={{ borderRadius: "50%" }}
                                                className={classes.imgAvatar}
                                                src={Config.getUserPicture(getUser.UserPictureURL)}
                                            />
                                        ) : (
                                            <InlineSVG
                                                className={classes.imgAvatar}
                                                src={require("../../../../assets/images/general/user_default.svg")}
                                            />
                                        )}
                                    </div>
                                    <div style={{ paddingLeft: 15 }}>
                                        {arrEmployeeInfo.map(({ content }, i) => (
                                            <div key={i}>{content}</div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Grid container className={classes.gridVertical}>
                                    {arrLabelLevels.map((item, i) => (
                                        <React.Fragment key={i}>
                                            <Typography uppercase type={"h5"} style={{ padding: spacing(3) }}>
                                                {item.label}
                                            </Typography>
                                            {arrLabelLevels.length !== i + 1 ? (
                                                <Divider style={{ height: 37 }} direction={"vertical"} />
                                            ) : (
                                                ""
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Col>
                        </Row>
                    </FormGroup>
                    {arrListOfCriteria.map((itemGr, iGr) => {
                        const appCriterionGroupNameU = _.get(itemGr, "AppCriterionGroupNameU", "");
                        const appCriterionGroupDescU = _.get(itemGr, "AppCriterionGroupDescU", "");
                        return (
                            <FormGroup key={iGr}>
                                <FormGroup style={{ marginBottom: 7 }}>
                                    <Row>
                                        <Col xs={12}>
                                            <Tooltip title={appCriterionGroupDescU} aria-label={appCriterionGroupDescU}>
                                                <Typography type={"h1"} color={normal_label} className={`${classes.pointer}`}>
                                                    {`${this.convertNumberToRoman(iGr + 1)}. ${appCriterionGroupNameU} `}
                                                </Typography>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                {itemGr.dataEvaluationElm.filter(item => !Boolean(item.IsMiddleEvaluationElement)).map((itemByID, iElm) => {
                                    const note = _.get(itemByID, "Note", "");
                                    const typeEvaluationName = [
                                        Config.lang("Diem"),
                                        Config.lang("Xep_loai"),
                                        Config.lang("Cong_thuc"),
                                    ];
                                    const proportion = {
                                        value: itemByID.Proportion,
                                        isVisible: _.get(itemByID, "Proportion", 0) > 0
                                    };
                                    const evaluationElementName = _.get(itemByID, "EvaluationElementName", "");
                                    const isTypeEvaluation = _.get(itemByID, "IsTypeEvaluation", "") || 0;
                                    const resultDes = _.get(itemByID, "ResultDes", "")
                                    const arrEvaluationValue = [
                                        {
                                            value: _.get(itemByID, "Value", 0),
                                            name: _.get(itemByID, "TypeName", ""),
                                            isVisible: true,
                                        },
                                        {
                                            value: _.get(itemByID, "Value02", 0),
                                            name: _.get(itemByID, "Type02Name", ""),
                                            isVisible: numEvaluationLevel2,
                                        },
                                        {
                                            value: _.get(itemByID, "Value03", 0),
                                            name: _.get(itemByID, "Type03Name", ""),
                                            isVisible: numEvaluationLevel3,
                                        },
                                        {
                                            value: _.get(itemByID, "Value04", 0),
                                            name: _.get(itemByID, "Type04Name", ""),
                                            isVisible: numEvaluationLevel4,
                                        },
                                        {
                                            value: _.get(itemByID, "Value05", 0),
                                            name: _.get(itemByID, "Type05Name", ""),
                                            isVisible: numEvaluationLevel5,
                                        }
                                    ].filter((item) => Boolean(item.isVisible));
                                    const arrEvaluationNote = [
                                        { note: _.get(itemByID, "Note1U", ""), isVisible: true },
                                        {
                                            note: _.get(itemByID, "Note2U", ""),
                                            isVisible: numEvaluationLevel2,
                                        },
                                        {
                                            note: _.get(itemByID, "Note3U", ""),
                                            isVisible: numEvaluationLevel3,
                                        },
                                        {
                                            note: _.get(itemByID, "Note4U", ""),
                                            isVisible: numEvaluationLevel4,
                                        },
                                        {
                                            note: _.get(itemByID, "Note5U", ""),
                                            isVisible: numEvaluationLevel5,
                                        }
                                    ].filter((item) => Boolean(item.isVisible));
                                    return (
                                        <div key={iElm}>
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    className={classes.flexRow}
                                                    style={{
                                                        justifyContent: "space-between",
                                                        paddingTop: iElm >= 1 ? 15 : 0
                                                    }}
                                                >
                                                    <span className={`${classes.muiFormLabelRootGeneral} ${classes.innerHTML}`}
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(`${iElm + 1}. ${evaluationElementName} `),
                                                        }}
                                                    />
                                                    <Typography
                                                        type={"p3"}
                                                        style={{ fontStyle: "italic", whiteSpace: 'noWrap', paddingBottom: 5 }}>
                                                        ({!!resultDes ? resultDes : typeEvaluationName[isTypeEvaluation]})
                                                    </Typography>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} className={classes.flexRow}
                                                    style={{ justifyContent: "space-between" }}>
                                                    <Typography type={"p3"} style={{ width: Config.isMobile ? "50%" : "90%" }}
                                                        className={`${classes.note} `}
                                                    >{note}</Typography>
                                                    {proportion.isVisible ? (
                                                        <Typography
                                                            type={"p3"}
                                                            className={`${classes.proportion} `}
                                                        >{proportion.value}</Typography>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                                <Col xs={2}></Col>
                                            </Row>
                                            <Row>
                                                {arrEvaluationValue.map((item, i) => {
                                                    let evalVal = item.value;
                                                    if (item.name && (isTypeEvaluation === 0 || isTypeEvaluation === 2)) {
                                                        evalVal = `${item.value} (${item.name})`;
                                                    }
                                                    return (
                                                        <Col key={i} sm={sizeCol} {...(numEvaluationLevel === 5 ? { style: { width: '20%' } } : {})}>
                                                            <TextInput readOnly={true} value={evalVal} />
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                            <Row>
                                                {arrEvaluationNote.map((item, i) => (
                                                    <Col key={i} sm={sizeCol} {...(numEvaluationLevel === 5 ? { style: { width: '20%' } } : {})}>
                                                        <TextInput readOnly multiline maxRows={5}
                                                            value={item.note} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    );
                                })}
                            </FormGroup>
                        );
                    })}
                </FormGroup>
                {isEvaluateContract ? (
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Typography type={"h1"} color={normal_label}>
                                    * {Config.lang("Ket_qua_danh_gia")}
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            {arrResultString.map((item, i) => (
                                <Col key={i} sm={sizeCol} {...(numEvaluationLevel === 5 ? { style: { width: '20%' } } : {})}>
                                    <TextInput readOnly value={item.result} />
                                </Col>
                            ))}
                        </Row>
                    </FormGroup>
                ) : (
                    ""
                )
                }
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Typography type={"h1"} color={normal_label}>
                                * {Config.lang("Danh_gia_chung")}
                            </Typography>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 15 }}>
                        {arrLabelLevels.map((item, i) =>
                        (!!item.note ? <Row key={i} style={{ display: 'flex', alignItems: 'end' }}>
                            <Col xs={3}>
                                <Typography uppercase type={"h2"} color={normal_label} style={{ marginBottom: 20 }}>
                                    {_.join(_.compact([item.label, " "]), ":")}
                                </Typography>
                            </Col>
                            <Col xs={9}>
                                <TextInput readOnly multiline maxRows={5} value={item.note} />
                            </Col>
                        </Row> : ""))}
                    </div>
                </FormGroup>
                {isModal ? (
                    <FormGroup>
                        <Paper className={classes.paper} >
                            <div style={{ display: 'block', width: '100%' }}>
                                <div className={classes.attachToolbar}>
                                    <Typography type={"p3"}>{Config.lang("Dinh_kem")}</Typography>
                                </div>
                                {getAttachments && getAttachments.length > 0 ? (
                                    <div className={"display_row align-center"}>
                                        {getAttachments.map((e, indx) => {
                                            const ext = _.get(e, "FileExt", "").replace(".", "");
                                            const fileUrl = _.get(e, "URL", "");
                                            return (
                                                <Col key={indx} xs={6} sm={4} md={3} lg={2}
                                                    className={"mgb10"}
                                                    style={{ height: 165, padding: '0 10px', zIndex: 99 }}>
                                                    <div className={classes.attachItem}>
                                                        <div onClick={() => this.onViewFile(e)}
                                                            style={{ width: '100%', height: '100%' }}
                                                            className={"display_row valign-middle align-center"}>
                                                            {['jpg', 'jpeg', 'png', 'bmp', 'svg', 'gif'].indexOf(ext) > -1 && fileUrl ? (
                                                                <Image
                                                                    src={fileUrl.indexOf('http') > -1 ? fileUrl : Config.getCDNPath() + fileUrl}
                                                                    style={{
                                                                        maxWidth: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'contain'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div className={classes.attachItem}>
                                                                    <div className={'att-wrap-icon'}>
                                                                        <Image style={{ height: 50 }}
                                                                            src={this.getIconFile(ext + '.svg')} />
                                                                    </div>
                                                                    <div style={{
                                                                        textAlign: 'center',
                                                                        fontSize: 12,
                                                                        maxHeight: '42%',
                                                                        padding: '0 10px',
                                                                        wordBreak: 'break-all'
                                                                    }}>
                                                                        {e.FileName}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className={"att-backdrop-item"} />
                                                            <div className={"att-info-item"}>
                                                                <Tooltip title={e.FileName}>
                                                                    <div className={"att-item-name"}>
                                                                        {e.FileName}
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={"display_row align-center align-between att-item-detail"}>
                                                            <div className={"att-item-type"}>
                                                                <Image style={{ height: 15 }}
                                                                    src={this.getIconFile(ext + '.svg')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div style={{ padding: "8% 0" }}
                                        className={"display_row align-center valign-middle"}>
                                        {Config.lang("Khong_co_du_lieu")}
                                    </div>
                                )}
                            </div>
                        </Paper>
                    </FormGroup>
                ) : (
                    ""
                )
                }
            </>
        );
    };

    onOpenModalW39F2030 = (status) => {
        if (_.isBoolean(status)) this.setState({ openModalW39F2030: status });
    };

    render() {
        const {
            dataForm,
            VoucherID,
            showZoomFormInfo,
            gridLoading,
            loading,
            fileOpened,
            showFileViewer,
            iPermission,
            openModalW39F2030
        } = this.state;
        if (!iPermission) return null;
        const { FormID } = this.defaultParams;
        return (
            <>
                {openModalW39F2030 && <W39F2035
                    openModal={openModalW39F2030}
                    onOpenModal={(status, reload) => {
                        if (_.isBoolean) this.setState({ openModalW39F2030: status });
                        if (reload === true) this.loadGrid(); // Load lại lưới
                    }}

                />}
                <FormGroup>
                    <ActionToolbar title={Config.lang("Duyet_danh_gia_nhan_vien_va_HDLD")} >
                        <Button
                            disabled={gridLoading || loading}
                            size={"medium"}
                            color={"primary"}
                            viewType={"filled"}
                            label={Config.lang("Duyet_hang_loat")}
                            onClick={() => this.onOpenModalW39F2030(true)}
                        />
                    </ActionToolbar>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Modal
                            style={{ zIndex: 1500 }}
                            open={showZoomFormInfo}
                            onClose={() => {
                                this.setState({
                                    showZoomFormInfo: false,
                                });
                            }}
                        >
                            <ModalHeader>{Config.lang("Chi_tiet_duyet_danh_gia_nhan_vien_va_HDLD")}</ModalHeader>
                            <ModalBody>{this.renderFormInfo(dataForm, true)}</ModalBody>
                        </Modal>
                        <Modal
                            style={{ zIndex: 1504 }}
                            open={showFileViewer}
                            onClose={this.onCloseViewFile}
                        >
                            <ModalBody>
                                <FileViewer
                                    fileName={fileOpened.FileName}
                                    filePath={fileOpened.URL}
                                    fileType={fileOpened.FileExt}
                                    height={"100%"}
                                />
                            </ModalBody>
                        </Modal>
                        <Col xs={12}>
                            <Approvals
                                allowHistoryApproval
                                parentProps={this.props}
                                FormID={FormID}
                                loading={gridLoading || loading}
                                ref={"Approvals"}
                                keyExpr={"CreateUserID"}
                                dataForm={dataForm}
                                selectedRowKey={VoucherID}
                                singleClick
                                filterRender={this.renderFilter}
                                formInfoRender={(data) => this.renderFormInfo(data)}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    }

    componentDidMount = async () => {
        await this.loadPermission();
        this.loadCboStatus();
        this.loadCboTransType();
        this.loadCboEmployees();
    };
}

export default compose(
    connect(
        (state) => ({
            getCboStatus: state.approvals.getCboStatus,
            getCboApprovalTransType: state.approvals.getCboApprovalTransType,
            getAttachments: state.approvals.getAttachments,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            approvalActions: bindActionCreators(ApprovalActions, dispatch),
        }),
    ))(W39F2030);
