/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI 
 * @create 31/12/2021
 * @Example
 */
import { LoadPanel } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import {
    Button, ButtonIcon, Checkbox, DateRangePicker, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, TreeView, Typography
} from 'diginet-core-ui/components';
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import Config from '../../../../config';
import * as generalActions from "../../../../redux/general/general_actions";
import * as w39F2035Actions from '../../../../redux/W3X/W39F2035/W39F2035_actions';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from '../../../grid-container/grid-container';
import { useStyles } from './styles';
import W39F2035ModalFormInfo from "./W39F2035ModalFormInfo";

const W39F2035 = (props) => {
    const dispatch = useDispatch();
    const { openModal, onOpenModal, getOrgCharts: dataOrgCharts = [] } = props;
    const [minimum, setMinimum] = useState(false);
    const [isShowSelected, setIsShowSelected] = useState(null);
    const [gridLoading, setGridLoading] = useState(false);
    const [cboTransTypeLoading, setCboTransTypeLoading] = useState(false);
    const [cboEmployeeLoading, setCboEmployeeLoading] = useState(false);
    const [showFormInfo, setShowFormInfo] = useState(false);
    const [cboOrgChartLoading, setCboOrgChartLoading] = useState(false);
    const [currentRowClick, setCurrentRowClick] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dataCboTransType, setDataCboTransType] = useState([]);
    const [dataGrid, setDataGrid] = useState({ total: 0, rows: [] });
    const [dataCboEmployees, setDataCboEmployees] = useState({ rows: [], total: 0 });
    const [filterSearch, setFilterSearch] = useState({
        DateFrom: null,
        DateTo: null,
        ApprovalLevelCreate: null,
        EmployeeID: "",
        TransTypeID: "",
        OrgchartID: []
    });
    const [rangeDate, setRangeDate] = useState([null, null]);

    const classes = useStyles({
        minimum
    });

    const filterSearchPages = useRef({
        skip: 0,
        limit: 10,
    });
    const filterCboEmployees = useRef({
        skip: 0,
        limit: 20,
        strSearch: ""
    });
    const timer = useRef(null);
    const gridRef = useRef(null);
    const gridReference = useRef(null);
    const changePage = useRef(false);
    const tmpSelectedRowData = useRef([]);
    const tmpSelectedRowKeys = useRef([]);
    const dataSource = useRef({ total: 0, rows: [] });

    const loadGrid = () => {
        const { skip, limit } = filterSearchPages.current;
        const { OrgchartID, EmployeeID, DateFrom, DateTo, ApprovalLevelCreate, TransTypeID } = filterSearch;
        const params = {
            FormID: "W39F2035",
            OrgchartID: JSON.stringify(OrgchartID),
            ApprovalLevelCreate,
            TransTypeID,
            EmployeeID,
            DateFrom,
            DateTo,
            skip,
            limit
        };
        setGridLoading(true);
        dispatch(w39F2035Actions.loadGrid(params, (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const total = _.get(data, "total", 0);
                const rows = _.get(data, "rows", []);
                dataSource.current = {
                    total,
                    rows
                };
                setDataGrid({
                    total,
                    rows
                });
            }
        }));
    };

    useEffect(() => {
        checkPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (_.isNumber(filterSearch.ApprovalLevelCreate)) {
            loadGrid();
            loadCboTransType();
            loadCboEmployees();
            loadDataOrgChart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSearch.ApprovalLevelCreate]);

    let dataGridFilter = dataGrid;
    if (isShowSelected) {
        dataGridFilter = {
            rows: tmpSelectedRowData.current ?? [],
            total: _.get(dataSource.current, "total", 0)
        };
    };

    const loadCboEmployees = (isReset) => {
        const { skip, limit, strSearch: search = "" } = filterCboEmployees.current;
        const param = {
            Mode: 0,
            FormID: "W39F2035",
            Language: Config.language || '84',
            search,
            skip,
            limit
        };
        setCboEmployeeLoading(true);
        dispatch(generalActions.getCboEmployees(param, (error, data) => {
            setCboEmployeeLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.EmployeeID) || _.isEmpty(item.EmployeeName) ? "" : "-";
                        return ({ ...item, EmployeeCustomName: `${item.EmployeeID} ${line} ${item.EmployeeName}` })
                    });
                }
                setDataCboEmployees({
                    rows: isReset ? rows : _.get(dataCboEmployees, "rows", []).concat(rows),
                    total
                });
            }
        }));
    };

    const loadCboTransType = () => {
        const params = {
            FormID: "W39F2035",
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
        };
        setCboTransTypeLoading(true);
        dispatch(w39F2035Actions.loadCboTransType(params, (error, data) => {
            setCboTransTypeLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataCboTransType(data);
            }
        }));
    };

    const checkPage = () => {
        dispatch(w39F2035Actions.checkPage((error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setFilterSearch({ ...filterSearch, ApprovalLevelCreate: _.get(data, "ApprovalLevelCreate", 0) });
            }
        }));
    };

    const loadDataOrgChart = () => {
        setCboOrgChartLoading(true);
        dispatch(generalActions.getOrgCharts({}, (error) => {
            setCboOrgChartLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const search = () => {
        changePage.current = true;
        filterSearchPages.current.skip = 0;
        loadGrid();
    };

    const collapsePanel = (status = false) => {
        if (_.isBoolean(status)) {
            setMinimum(status);
            setTimeout(() => {
                gridReference.current.instance.resize();
            }, 500); // reSize lại lưới sau 5s theo transition
        }
    };

    const changeTypeShow = (e) => {
        if (!e) return false;
        changePage.current = true;
        const value = e?.value;
        if (_.isBoolean(value)) setIsShowSelected(value);
    };

    const handleChange = (filterName, e) => {
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        switch (filterName) {
            case "TransTypeID":
            case "OrgchartID":
                setFilterSearch({ ...filterSearch, [filterName]: value });
                break;
            case "EmployeeID":
                filterSearch[filterName] = value;
                break;
            case "RangePicker":
                const DateFrom = _.isArray(value) ? value[0] : value;
                const DateTo = _.isArray(value) ? value[1] : value;
                filterSearch.DateFrom = DateFrom;
                filterSearch.DateTo = DateTo;
                setRangeDate([DateFrom, DateTo]);
                break;
            default:
                break;
        }
    };

    const onChangePage = (page) => {
        if (_.isNumber(page)) {
            changePage.current = true;
            filterSearchPages.current.skip = page * filterSearchPages.current.limit;
            loadGrid();
        }
    };

    const onChangePerPage = (perPage) => {
        if (_.isNumber(perPage)) {
            changePage.current = true;
            filterSearchPages.current.skip = 0;
            filterSearchPages.current.limit = perPage;
            loadGrid();
        }
    };

    const handleChecked = (currentSelectedRowKeys, keyExpr) => {
        if (_.isEmpty(tmpSelectedRowData.current) && !_.isEmpty(tmpSelectedRowKeys.current)) tmpSelectedRowKeys.current = [];
        currentSelectedRowKeys.forEach((val) => {
            if (tmpSelectedRowData.current.indexOf(val) < 0 && !tmpSelectedRowKeys.current.includes(val[keyExpr])) {
                tmpSelectedRowData.current.push(val);
                tmpSelectedRowKeys.current.push(val[keyExpr]);
            }
        });
    };

    const handleUnChecked = (currentDeselectedRowKeys, keyExpr) => {
        tmpSelectedRowData.current = tmpSelectedRowData.current.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
        });
        tmpSelectedRowKeys.current = tmpSelectedRowKeys.current.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e) < 0;
        });
    };

    const onSelectionChanged = (e) => {
        const mode = "multiple";
        const keyExpr = "VoucherID";
        const currentSelectedRowsData = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (changePage.current === true) {
            if (currentSelectedRowsData.length > 0) {
                handleChecked(currentSelectedRowsData, keyExpr, mode);
            }
            changePage.current = false;
        } else {
            if (currentDeselectedRowKeys.length > 0) {// UnSelect
                handleUnChecked(currentDeselectedRowKeys, keyExpr);
            }
            else if (currentSelectedRowsData.length > 0) {
                if (mode === "multiple") { //Multiple Select
                    handleChecked(currentSelectedRowsData, keyExpr, mode);
                }
                if (mode === "single") { //Select one only
                    currentSelectedRowsData.forEach((val) => {
                        tmpSelectedRowData.current = [val]
                        tmpSelectedRowKeys.current = [val[keyExpr]];
                    });
                }
            }
        }
        setSelectedRowKeys(e.selectedRowKeys);
    };

    const approval = (mode) => {
        if (!_.isNumber(mode)) return false;
        if (tmpSelectedRowData.current?.length > 0 || !_.isEmpty(selectedRowKeys)) {
            Config.popup.show("YES_NO", Config.lang(`Ban_co_chac_chan_muon_${mode ? "tu_choi" : "duyet"}`), () => saveApproval(mode));
        } else {
            Config.popup.show("WARNING", Config.lang("Ban_chua_chon_nhan_vien"));
        }
    };

    const getEmployeesData = () => {
        if (_.isEmpty(tmpSelectedRowData.current)) return [];
        return tmpSelectedRowData.current.map(item => ({
            VoucherID: item.VoucherID,
            ApprovalLevel: item.ApprovalLevel,
            ApprovalFlowID: item.ApprovalFlowID,
            EmployeeID: item.EmployeeID,
        }));
    };

    const saveApproval = (mode) => {
        if (_.isEmpty(tmpSelectedRowData.current)) return;
        const params = {
            Mode: mode,
            FormID: "W39F2035",
            employees: getEmployeesData()
        };
        setGridLoading(true);
        dispatch(w39F2035Actions.approval(params, (error, data) => {
            setGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const status = _.get(data, "Status", null);
                if (status === 0) {
                    Config.notify.show('success', Config.lang(`${mode ? "Tu_choi" : "Duyet"}_thanh_cong`), 2000);
                    onOpenModal(false, true); // Đóng popup lại và load lại lưới cha
                } else if (status === 1) {
                    const message = _.get(data, "Message", "") || Config.lang(`${mode ? "Tu_choi" : "Duyet"}_that_bai`);
                    Config.popup.show('INFO', message);
                }
            }
        }));
    };

    const DateCellRender = (e) => {
        const { DateFrom, DateTo } = _.get(e, "row.data", {});
        const line = _.isEmpty(DateFrom) || _.isEmpty(DateTo) ? "" : "-";
        return `${DateFrom ? moment(DateFrom).format("DD/MM/YYYY") : ""} ${line} ${DateTo ? moment(DateTo).format("DD/MM/YYYY") : ""}`;
    };

    const renderMaxWidthColum = (e, maxWidth = 260) => {
        const value = _.get(e, "value", "");
        return <div title={value} className={classes.ellipsis}
            {...(_.isNumber(maxWidth) ? { style: { maxWidth } } : {})} >{value}</div>
    };

    const onOpenMenu = (status, data) => {
        if (!_.isBoolean(status)) return;
        setCurrentRowClick(data);
        setShowFormInfo(status); // Mở Modal lên
    };

    const renderActionGrid = (e) => {
        const currentRowSelected = _.get(e, "data", {});
        return (
            <GridActionBar >
                <ButtonIcon
                    circular
                    name={"View"}
                    size={"medium"}
                    viewType={"text"}
                    onClick={() => onOpenMenu(true, currentRowSelected)}
                    style={{ cursor: 'pointer' }} />
            </GridActionBar>
        );
    };

    return (
        <>
            <Modal id={"Popup-W39F2035"}
                zIndex={1020}
                width={"1440"}
                open={openModal}
                onClose={() => {
                    if (onOpenModal) {
                        onOpenModal(false);
                    }
                }}>
                <ModalHeader>
                    {Config.lang("Duyet_danh_gia_nhan_vien_va_HDLD_hang_loat")}
                </ModalHeader>
                <ModalBody>
                    {showFormInfo &&
                        <W39F2035ModalFormInfo
                            dataInfo={currentRowClick}
                            openModal={showFormInfo}
                            onOpenModal={(status) => {
                                if (_.isBoolean(status)) onOpenMenu(status);
                            }}
                        />}
                    <LoadPanel
                        shading={true}
                        showPane={true}
                        showIndicator={true}
                        visible={gridLoading}
                        shadingColor={'rgba(0,0,0,0.4)'}
                        position={{ my: 'center', of: '#Popup-W39F2035' }}
                    />
                    <Row>
                        <Col xs={12} sm={12} md={5} lg={4} className={`${classes.panel} ${classes.leftCol} `}>
                            <Row>
                                <ActionToolbar
                                    showBorder={false}
                                    upperCase={false}
                                    alignment={"space-between"}
                                    className={`${classes.actionToolbarFilter} `}
                                >
                                    <div className={`${classes.flex} ${classes.alignCenter} ${classes.justifyCenter} ${classes.gap10} `}>
                                        <ButtonIcon
                                            circular
                                            name={"Filter"}
                                            size={"medium"}
                                            viewType={"text"}
                                            onClick={() => collapsePanel(false)}
                                        />
                                        {!minimum && <Typography className={classes.nowrap}>{Config.lang("Tieu_chi_loc")}</Typography>}
                                    </div>
                                    {!minimum && (
                                        <ButtonIcon
                                            circular
                                            name={"ArrowLeft"}
                                            size={"medium"}
                                            viewType={"text"}
                                            onClick={() => collapsePanel(true)}
                                        />
                                    )}
                                </ActionToolbar>
                            </Row>
                            <div className={`${classes.panelForm} form - field ${minimum === true ? "hide" : ""} `}>
                                <div className={`${classes.mgb15} `}>
                                    <div className={`${classes.flex} ${classes.alignCenter} ${classes.justifyBetween} `}>
                                        <Checkbox
                                            color={"primary"}
                                            checked={_.isNull(isShowSelected) ? false : isShowSelected}
                                            className={`${classes.nowrapSpan} `}
                                            label={Config.lang("Hien_thi_du_lieu_da_chon")}
                                            onChange={changeTypeShow}
                                        />
                                        <Typography>{`(${tmpSelectedRowKeys.current?.length ?? 0})`}</Typography>
                                    </div>
                                </div>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            clearAble
                                            viewType={"outlined"}
                                            keyExpr={"TransTypeName"}
                                            valueExpr={"TransTypeID"}
                                            displayExpr={"TransTypeName"}
                                            placeholder={Config.lang("Chon")}
                                            label={Config.lang("Loai_danh_gia")}
                                            dataSource={dataCboTransType}
                                            loading={cboTransTypeLoading}
                                            value={filterSearch.TransTypeID}
                                            onChange={e => handleChange("TransTypeID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            multiple
                                            clearAble
                                            keyExpr={"OrgName"}
                                            viewType={"outlined"}
                                            displayExpr={"OrgName"}
                                            valueExpr={"OrgChartID"}
                                            label={Config.lang("Co_cau_to_chuc")}
                                            placeholder={Config.lang("Chon")}
                                            loading={cboOrgChartLoading}
                                            dataSource={dataOrgCharts}
                                            value={filterSearch.OrgchartID}
                                            onChange={(e) => handleChange("OrgchartID", e)}
                                        >
                                            <TreeView
                                                multiple
                                                allowSearch
                                                id={"OrgChartID"}
                                                displayExpr={"OrgName"}
                                                valueExpr={"OrgChartID"}
                                                multipleValueMode={"single"}
                                                parentID={"OrgChartParentID"}
                                                dataSource={dataOrgCharts}
                                                value={filterSearch.OrgchartID}
                                                onChange={(e) => handleChange("OrgchartID", e)}
                                            />
                                        </Dropdown>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            clearAble
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            displayExpr={"EmployeeCustomName"}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            loading={cboEmployeeLoading}
                                            value={filterSearch.Employee}
                                            dataSource={_.get(dataCboEmployees, "rows", [])}
                                            total={_.get(dataCboEmployees, "total", 0)}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            iconExpr={{ key: 'UserPictureURL', prefix: Config.getCDNPath() }}
                                            onChange={e => handleChange("EmployeeID", e)}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                if (timer.current) clearTimeout(timer.current);
                                                timer.current = setTimeout(() => {
                                                    filterCboEmployees.current.strSearch = value;
                                                    filterCboEmployees.current.skip = 0;
                                                    loadCboEmployees(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <DateRangePicker
                                            clearAble
                                            controls
                                            value={rangeDate}
                                            viewType={"outlined"}
                                            label={Config.lang("Thoi_gian_danh_gia")}
                                            onChange={e => handleChange("RangePicker", e)}
                                            placeholder={"dd/mm/yyyy - dd/mm/yyyy"}
                                            returnFormat={"YYYY-MM-DD"}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                                        <Button
                                            className={`${classes.textUppercase} `}
                                            color={"primary"}
                                            viewType={"outlined"}
                                            startIcon={"Search"}
                                            text={Config.lang("Tim_kiem")}
                                            onClick={search}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={7} lg={8} className={`${classes.panel} ${classes.rightCol} `}>
                            <GridContainer
                                ref={gridRef}
                                reference={ref => gridReference.current = ref}
                                keyExpr={"VoucherID"}
                                typePaging={"remote"}
                                height={'calc(100vh - 230px)'}
                                pagerFullScreen={false}
                                columnAutoWidth={true}
                                allowColumnResizing={true}
                                columnResizingMode={"widget"}
                                gridProps={{ style: { minHeight: 400 } }}
                                typeShort={window.innerWidth < 768}
                                disabled={gridLoading}
                                listPerPage={[20, 40, 60, 80]}
                                totalItems={_.get(dataGridFilter, "total", 0)}
                                dataSource={_.get(dataGridFilter, "rows", [])}
                                selection={{
                                    allowSelectAll: true,
                                    mode: 'multiple',
                                    selectAllMode: 'allPages',
                                    showCheckBoxesMode: 'always'
                                }}
                                selectedRowKey={tmpSelectedRowKeys.current}
                                itemPerPage={filterSearchPages.current.limit}
                                skipPerPage={filterSearchPages.current.skip}
                                onChangePage={onChangePage}
                                onChangePerPage={onChangePerPage}
                                onSelectionChanged={(e) => onSelectionChanged(e)}
                            >
                                <Column
                                    fixed={true}
                                    alignment={"right"}
                                    fixedPosition={"right"}
                                    caption={Config.lang("Hanh_dong")}
                                    visible={Config.isMobile}
                                    cellRender={renderActionGrid}
                                />
                                <Column
                                    width={160}
                                    dataField={"EmployeeID"}
                                    caption={Config.lang("Ma_nhan_vien")}
                                />
                                <Column
                                    width={220}
                                    dataField={"EmployeeName"}
                                    caption={Config.lang("Ten_nhan_vien")}
                                />
                                <Column
                                    width={180}
                                    dataField={"TransTypeName"}
                                    caption={Config.lang("Loai_danh_gia")}
                                />
                                <Column
                                    width={240}
                                    dataType={"date"}
                                    alignment={"center"}
                                    format={"dd/MM/yyyy"}
                                    dataField={"DateFrom"}
                                    caption={Config.lang("Thoi_gian")}
                                    cellRender={DateCellRender}
                                />
                                <Column
                                    minWidth={100}
                                    dataField={"Note"}
                                    caption={Config.lang("Ghi_chu")}
                                    cellRender={(e) => renderMaxWidthColum(e, 280)}
                                />
                                <Column
                                    width={70}
                                    fixed={true}
                                    alignment={"right"}
                                    fixedPosition={"right"}
                                    visible={!Config.isMobile}
                                    cellRender={renderActionGrid}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ marginRight: 8 }}
                        className={`${classes.textUppercase} `}
                        color={"success"}
                        viewType={"filled"}
                        startIcon={"Approval"}
                        text={Config.lang("Duyet")}
                        onClick={() => approval(0)}
                    />
                    <Button
                        className={`${classes.textUppercase} `}
                        color={"danger"}
                        viewType={"filled"}
                        startIcon={"Cancel"}
                        text={Config.lang("Tu_choi")}
                        onClick={() => approval(1)}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
};

W39F2035.propTypes = {
    openModal: PropTypes.bool,
    onOpenModal: PropTypes.func,
};

W39F2035.defaultProps = {
    open: false,
};

export default compose(connect((state) => ({
    getOrgCharts: state.general.getOrgCharts,
}), null))(W39F2035);
