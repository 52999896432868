/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI 
 * @create 06/01/2022
 * @Example
 */
import { Grid } from "@material-ui/core";
import { LoadPanel } from 'devextreme-react';
import { Attachment, Divider, Modal, ModalBody, ModalHeader, TextInput, Tooltip, Typography } from 'diginet-core-ui/components';
import DOMPurify from "dompurify";
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Col, FormGroup, Image, Row } from 'react-bootstrap';
import InlineSVG from "react-inlinesvg";
import { useDispatch } from 'react-redux';
import Config from '../../../../config';
import * as approvalActions from "../../../../redux/approvals/approvals_actions";
import { makeStyles, useTheme } from "diginet-core-ui/theme";

const useStyles = makeStyles((theme) => ({
    flexRow: {
        width: "99%",
        display: "flex",
    },
    headerTitle: {
        margin: "auto"
    },
    note: {
        width: "90%",
        whiteSpace: "pre-wrap",
        fontStyle: 'italic !important',
    },
    imageRounded: {
        height: 65,
        width: 65,
        "& > img": {
            objectFit: "cover",
            height: 65,
            width: 65,
        }
    },
    imgAvatar: {
        width: '100%',
        height: '100%',
        objectFit: "contain",
    },
    gridVertical: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        border: `1px solid ${theme?.boxShadows?.large}`,
        borderRadius: theme?.border?.radius,
        backgroundColor: "#F0F0F3",
        color: "black",
        "& > .MuiTypography-root": {
            margin: theme?.spacing(1.5),
        }
    },
    muiFormLabelRootGeneral: {
        color: `${theme?.colors?.text?.normal_label} !important`,
        lineHeight: `${theme?.typography?.label?.lineHeight} !important`,
        fontFamily: `${theme?.typography?.label?.fontFamily} !important`,
    },
    innerHTML: {
        fontWeight: 400,
        fontSize: '1.1rem'
    },
    proportion: {
        padding: 0,
        height: 25,
        lineHeight: '25px !important',
        backgroundColor: '#CDCDCD',
        textAlign: 'center'
    }
}));

const W39F2035 = (props) => {
    const coreTheme = useTheme();
    const { spacing, colors: { text: { normal_label } } } = coreTheme || {};
    const classes = useStyles();
    const dispatch = useDispatch();
    const { openModal, onOpenModal, dataInfo } = props;
    const [formLoading, setFormLoading] = useState(false);
    const [dataFormInfo, setDataFormInfo] = useState({});
    const [dataOldAttachments, setDataOldAttachments] = useState([]);

    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const defaultParams = useRef({
        FormID: "W39F2030",
        DivisionID: Config.getDivisionID(),
        Language: Config.language || "84",
    });

    useEffect(() => {
        loadFormInfo();
        loadListAttachments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadFormInfo = () => {
        const VoucherID = _.get(dataInfo, "VoucherID", "");
        const params = {
            VoucherID,
            ...defaultParams.current,
        };
        setFormLoading(true);
        dispatch(approvalActions.getFormInfo(params, (error, data) => {
            setFormLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataFormInfo(data);
            }
        }));
    };

    const loadListAttachments = (VoucherID) => {
        const params = {
            FormID: "W39F2030",
            DivisionID: Config.getDivisionID(),
            VoucherID: VoucherID
        };
        dispatch(approvalActions.getAttachments(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataOldAttachments(data);
            }
        }));
    };

    const getStringNameResult = (resStrNumber) => {
        let resStrName = "";
        switch (resStrNumber) {
            case "01":
                resStrName = Config.lang("Dat");
                break;
            case "02":
                resStrName = Config.lang("Khong_dat");
                break;
            default:
                break;
        }
        return resStrName;
    };

    const convertNumberToRoman = (num) => {
        if (typeof num !== "number") return;
        let digits = String(+num).split(""),
            key = [
                "",
                "C",
                "CC",
                "CCC",
                "CD",
                "D",
                "DC",
                "DCC",
                "DCCC",
                "CM",
                "",
                "X",
                "XX",
                "XXX",
                "XL",
                "L",
                "LX",
                "LXX",
                "LXXX",
                "XC",
                "",
                "I",
                "II",
                "III",
                "IV",
                "V",
                "VI",
                "VII",
                "VIII",
                "IX",
            ],
            romanNum = "",
            i = 3;
        while (i--) romanNum = (key[+digits.pop() + i * 10] || "") + romanNum;
        return Array(+digits.join("") + 1).join("M") + romanNum;
    };

    const onChangeAttachments = (e) => {
        attachments.current = _.get(e, "allNewAttached", []);
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove = deletedFile.current.map(d => d.AttachmentID);
            setDataOldAttachments(dataOldAttachments.filter(att => {
                return _arrRemove.indexOf(att.AttachmentID) < 0;
            }));
        }
    };

    const renderFormInfo = (data) => {
        const getUser = Config.getUser({ EmployeeID: _.get(data, "EmployeeID") });
        const getAppCriterionGroup = _.get(data, "AppCriterionGroup", "");
        const getEvaluationElement = _.get(data, "EvaluationElement", "");
        const numEvaluationLevel = _.get(data, "NumEvaluationLevel", 0);
        const isEvaluateContract = _.get(data, "IsEvaluateContract", 0);
        const numEvaluationLevel2 = numEvaluationLevel >= 2;
        const numEvaluationLevel3 = numEvaluationLevel >= 3;
        const numEvaluationLevel4 = numEvaluationLevel >= 4;
        const numEvaluationLevel5 = numEvaluationLevel === 5;
        let sizeCol = 12;
        switch (numEvaluationLevel) {
            case 2:
                sizeCol = 6;
                break;
            case 3:
                sizeCol = 4;
                break;
            case 4:
            case 5:
                sizeCol = 3;
                break;
            default:
                break;
        }
        if (_.isEmpty(getAppCriterionGroup) || _.isEmpty(getEvaluationElement)) return "";
        const arrListOfCriteria = getAppCriterionGroup
            .map((itemGr) => ({
                ...itemGr,
                dataEvaluationElm: getEvaluationElement.filter((itemElm) => itemGr.AppCriterionGroupID === itemElm.AppCriterionGroupID),
            }))
            .filter((item) => item.dataEvaluationElm.length > 0);
        const arrEmployeeInfo = getUser
            ? [
                {
                    content: _.compact([getUser.EmployeeID, getUser.EmployeeName]).join(" - "),
                    isVisible: _.get(getUser, "EmployeeName", "") || _.get(getUser, "EmployeeID", ""),
                },
                {
                    content: _.compact([getUser.DutyName, getUser.DepartmentName]).join(" - "),
                    isVisible: _.get(getUser, "DutyName", "") || _.get(getUser, "DepartmentName", ""),
                },
                {
                    content: moment.utc(data.AppraisalDate).format("DD/MM/YYYY kk:mm:ss"),
                    isVisible: moment(_.get(_.get(data, "AppraisalDate", ""))).isValid(),
                },
                {
                    content: `${Config.lang("Danh_gia_hop_dong_thu_viec")}: ${data.WorkFormName} `,
                    isVisible: _.get(data, "IsEvaluateContract", false) && _.get(data, "WorkFormName", ""),
                },
            ].filter((item) => Boolean(item.isVisible))
            : [];
        const arrLabelLevels = [
            {
                label: _.get(data, "LabelLevel1", ""),
                note: _.get(data, "NoteEmployee", ""),
                isVisible: true
            },
            {
                label: _.get(data, "LabelLevel2", ""),
                note: _.get(data, "NoteEvaluatedLevel2", ""),
                isVisible: numEvaluationLevel2
            },
            {
                label: _.get(data, "LabelLevel3", ""),
                note: _.get(data, "NoteEvaluatedLevel3", ""),
                isVisible: numEvaluationLevel3
            },
            {
                label: _.get(data, "LabelLevel4", ""),
                note: _.get(data, "NoteEvaluatedLevel4", ""),
                isVisible: numEvaluationLevel4
            },
            {
                label: _.get(data, "LabelLevel5", ""),
                note: _.get(data, "NoteEvaluatedLevel5", ""),
                isVisible: numEvaluationLevel5
            }
        ].filter((item) => Boolean(item.isVisible));
        const arrResultString = [
            { result: getStringNameResult(_.get(data, "ResultID1", "")), isVisible: true },
            { result: getStringNameResult(_.get(data, "ResultID2", "")), isVisible: numEvaluationLevel2 },
            { result: getStringNameResult(_.get(data, "ResultID3", "")), isVisible: numEvaluationLevel3 },
            { result: this.getStringNameResult(_.get(data, "ResultID4", "")), isVisible: numEvaluationLevel4 },
            { result: this.getStringNameResult(_.get(data, "ResultID5", "")), isVisible: numEvaluationLevel5 }
        ].filter((item) => Boolean(item.isVisible));
        return (
            <>
                <FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <div className={classes.flexRow} style={{ justifyContent: "flex-end", marginTop: 8 }}>
                                    <Typography type={"title4"} color={normal_label} uppercase className={`${classes.headerTitle}`}>
                                        {_.get(data, "TransTypeName", "")}
                                    </Typography>
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Typography
                                    type={"p3"}
                                    style={{
                                        margin: Config.isMobile ? 0 : "auto",
                                        textAlign: Config.isMobile ? "left" : "center"
                                    }}
                                    className={`${classes.note} `}
                                >  {_.get(data, "Note", "")}</Typography>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className={classes.imageRounded}>
                                        {_.get(getUser, "UserPictureURL", "") ? (
                                            <Image
                                                style={{ borderRadius: "50%" }}
                                                className={classes.imgAvatar}
                                                src={Config.getUserPicture(getUser.UserPictureURL)}
                                            />
                                        ) : (
                                            <InlineSVG
                                                className={classes.imgAvatar}
                                                src={require("../../../../assets/images/general/user_default.svg")}
                                            />
                                        )}
                                    </div>
                                    <div style={{ paddingLeft: 15 }}>
                                        {arrEmployeeInfo.map(({ content }, i) => (
                                            <div key={i}>{content}</div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Grid container className={classes.gridVertical}>
                                    {arrLabelLevels.map((item, i) => (
                                        <React.Fragment key={i}>
                                            <Typography uppercase type={"h5"} style={{ padding: spacing(3) }}>
                                                {item.label}
                                            </Typography>
                                            {arrLabelLevels.length !== i + 1 ? (
                                                <Divider style={{ height: 37 }} direction={"vertical"} />
                                            ) : (
                                                ""
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Col>
                        </Row>
                    </FormGroup>
                    {arrListOfCriteria.map((itemGr, iGr) => {
                        const appCriterionGroupNameU = _.get(itemGr, "AppCriterionGroupNameU", "");
                        const appCriterionGroupDescU = _.get(itemGr, "AppCriterionGroupDescU", "");
                        return (
                            <FormGroup key={iGr}>
                                <FormGroup style={{ marginBottom: 7 }}>
                                    <Row>
                                        <Col xs={12}>
                                            <Tooltip title={appCriterionGroupDescU} aria-label={appCriterionGroupDescU}>
                                                <Typography type={"h1"} color={normal_label} className={`${classes.pointer}`}>
                                                    {`${convertNumberToRoman(iGr + 1)}. ${appCriterionGroupNameU} `}
                                                </Typography>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                {itemGr.dataEvaluationElm.filter(item => !Boolean(item.IsMiddleEvaluationElement)).map((itemByID, iElm) => {
                                    const note = _.get(itemByID, "Note", "");
                                    const typeEvaluationName = [
                                        Config.lang("Diem"),
                                        Config.lang("Xep_loai"),
                                        Config.lang("Cong_thuc"),
                                    ];
                                    const proportion = {
                                        value: itemByID.Proportion,
                                        isVisible: _.get(itemByID, "Proportion", 0) > 0
                                    };
                                    const evaluationElementName = _.get(itemByID, "EvaluationElementName", "");
                                    const isTypeEvaluation = _.get(itemByID, "IsTypeEvaluation", "") || 0;
                                    const resultDes = _.get(itemByID, "ResultDes", "")
                                    const arrEvaluationValue = [
                                        {
                                            value: _.get(itemByID, "Value", 0),
                                            name: _.get(itemByID, "TypeName", ""),
                                            isVisible: true,
                                        },
                                        {
                                            value: _.get(itemByID, "Value02", 0),
                                            name: _.get(itemByID, "Type02Name", ""),
                                            isVisible: numEvaluationLevel2,
                                        },
                                        {
                                            value: _.get(itemByID, "Value03", 0),
                                            name: _.get(itemByID, "Type03Name", ""),
                                            isVisible: numEvaluationLevel3,
                                        },
                                        {
                                            value: _.get(itemByID, "Value04", 0),
                                            name: _.get(itemByID, "Type04Name", ""),
                                            isVisible: numEvaluationLevel4,
                                        },
                                        {
                                            value: _.get(itemByID, "Value05", 0),
                                            name: _.get(itemByID, "Type05Name", ""),
                                            isVisible: numEvaluationLevel5,
                                        }
                                    ].filter((item) => Boolean(item.isVisible));
                                    const arrEvaluationNote = [
                                        { note: _.get(itemByID, "Note1U", ""), isVisible: true },
                                        {
                                            note: _.get(itemByID, "Note2U", ""),
                                            isVisible: numEvaluationLevel2,
                                        },
                                        {
                                            note: _.get(itemByID, "Note3U", ""),
                                            isVisible: numEvaluationLevel3,
                                        },
                                        {
                                            note: _.get(itemByID, "Note4U", ""),
                                            isVisible: numEvaluationLevel4,
                                        },
                                        {
                                            note: _.get(itemByID, "Note5U", ""),
                                            isVisible: numEvaluationLevel5,
                                        }
                                    ].filter((item) => Boolean(item.isVisible));
                                    return (
                                        <div key={iElm}>
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    className={classes.flexRow}
                                                    style={{
                                                        justifyContent: "space-between",
                                                        paddingTop: iElm >= 1 ? 15 : 0
                                                    }}
                                                >
                                                    <span className={`${classes.muiFormLabelRootGeneral} ${classes.innerHTML}`}
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(`${iElm + 1}. ${evaluationElementName} `),
                                                        }}
                                                    />
                                                    <Typography
                                                        type={"p3"}
                                                        style={{ fontStyle: "italic", whiteSpace: 'noWrap', paddingBottom: 5 }}>
                                                        ({!!resultDes ? resultDes : typeEvaluationName[isTypeEvaluation]})
                                                    </Typography>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} className={classes.flexRow}
                                                    style={{ justifyContent: "space-between" }}>
                                                    <Typography type={"p3"} style={{ width: Config.isMobile ? "50%" : "90%" }}
                                                        className={`${classes.note} `}
                                                    >{note}</Typography>
                                                    {proportion.isVisible ? (
                                                        <Typography
                                                            type={"p3"}
                                                            className={`${classes.proportion} `}
                                                        >{proportion.value}</Typography>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                                <Col xs={2}></Col>
                                            </Row>
                                            <Row>
                                                {arrEvaluationValue.map((item, i) => {
                                                    let evalVal = item.value;
                                                    if (item.name && (isTypeEvaluation === 0 || isTypeEvaluation === 2)) {
                                                        evalVal = `${item.value} (${item.name})`;
                                                    }
                                                    return (
                                                        <Col key={i} sm={sizeCol} {...(numEvaluationLevel === 5 ? { style: { width: '20%' } } : {})}>
                                                            <TextInput readOnly={true} value={evalVal} />
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                            <Row>
                                                {arrEvaluationNote.map((item, i) => (
                                                    <Col key={i} sm={sizeCol} {...(numEvaluationLevel === 5 ? { style: { width: '20%' } } : {})}>
                                                        <TextInput readOnly multiline maxRows={5}
                                                            value={item.note} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    );
                                })}
                            </FormGroup>
                        );
                    })}
                </FormGroup>
                {isEvaluateContract ? (
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Typography type={"h1"} color={normal_label}>
                                    * {Config.lang("Ket_qua_danh_gia")}
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            {arrResultString.map((item, i) => (
                                <Col key={i} sm={sizeCol} {...(numEvaluationLevel === 5 ? { style: { width: '20%' } } : {})}>
                                    <TextInput readOnly value={item.result} />
                                </Col>
                            ))}
                        </Row>
                    </FormGroup>
                ) : (
                    ""
                )}
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Typography type={"h1"} color={normal_label}>
                                * {Config.lang("Danh_gia_chung")}
                            </Typography>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 15 }}>
                        {arrLabelLevels.map((item, i) =>
                        (!!item.note ? <Row key={i} style={{ display: 'flex', alignItems: 'end' }}>
                            <Col xs={3}>
                                <Typography uppercase type={"h2"} color={normal_label} style={{ marginBottom: 20 }}>
                                    {_.join(_.compact([item.label, " "]), ":")}
                                </Typography>
                            </Col>
                            <Col xs={9}>
                                <TextInput readOnly multiline maxRows={5} value={item.note} />
                            </Col>
                        </Row> : ""))}
                    </div>
                </FormGroup>
                <FormGroup>
                    <Attachment
                        readOnly={true}
                        isStripDomain={true}
                        style={{ minHeight: 120 }}
                        data={dataOldAttachments}
                        domain={Config.getCDNPath()}
                        onChange={onChangeAttachments}
                        uploadErrorInfo={{
                            existingFile: Config.lang("File_da_duoc_dinh_kem"),
                            maxFile: Config.lang("File_vuot_qua_so_luong_cho_phep"),
                            maxSize: Config.lang("File_vuot_qua_dung_luong_cho_phep"),
                            fileType: Config.lang("File_khong_dung_dinh_dang"),
                        }}
                    />
                </FormGroup>
            </>
        );
    };

    return (
        <FormGroup>
            <Row>
                <Modal
                    id={"Popup-W39F2035Info"}
                    style={{ zIndex: 1500, minHeight: 500 }}
                    open={openModal}
                    onClose={() => {
                        if (_.isFunction(onOpenModal)) onOpenModal(false);
                    }}
                >
                    <ModalHeader>{Config.lang("Chi_tiet_duyet_danh_gia_nhan_vien_va_HDLD")}</ModalHeader>
                    <ModalBody>
                        <LoadPanel
                            shading={true}
                            showPane={true}
                            showIndicator={true}
                            visible={formLoading}
                            shadingColor={'rgba(0,0,0,0.4)'}
                            position={{ my: 'center', of: '#Popup-W39F2035Info' }}
                        />
                        {renderFormInfo(dataFormInfo)}
                    </ModalBody>
                </Modal>
            </Row>
        </FormGroup>
    );
};

W39F2035.propTypes = {
    openModal: PropTypes.bool,
    dataInfo: PropTypes.object,
    onOpenModal: PropTypes.func,
};

W39F2035.defaultProps = {
    openModal: false,
    dataInfo: {},
};

export default W39F2035;
