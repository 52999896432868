/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/14/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import * as W39F2000Actions from "../../../../redux/W3X/W39F2000/W39F2000_actions";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {browserHistory} from "react-router";
import {Column} from 'devextreme-react/data-grid';
import {Row, Col, FormGroup} from "react-bootstrap"
import GridContainer from "../../../grid-container/grid-container";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import withStyles from "@material-ui/core/styles/withStyles";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import IconButton from "@material-ui/core/IconButton";
import * as generalActions from "../../../../redux/general/general_actions";
import {Icon} from "@material-ui/core";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Rating from "@material-ui/lab/Rating";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005"
import HistoryIcon from "@material-ui/icons/History"
import _ from "lodash";

const styles = theme => ({
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    chip: {
        fontSize: '1rem',
        fontWeight: 400,
        border: 'none',
        backgroundColor: '#EBEBEB'
    },
    btnFilter: {
        backgroundColor: "#9999993d",
        textTransform: "uppercase"
    },
    divText: {
        overflow: "hidden",
        whiteSpace: "pre-line"

    },
    iconEmpty: {
        color: theme.palette.warning.main
    },
    iconCheckRoot: {
        color: theme.palette.info.main
    },
    iconCheckRootError: {
        color: `${theme.palette.error.main}!important`
    }
});

class W39F2000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataGrid: [],
            total: 0,
            gridLoading: false,
            loading: false,
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
        };
        this.filter = {
            limit: 10,
            skip: 0
        };
    }

    loadPermission = async () => {
        const FormBack = this.props.location?.state?.FormBack;
        await this.props.generalActions.getPermission("W39F2000", (isPer) => {
            this.setState({iPermission: FormBack === "W39F2011" ? 4 : isPer});
        }, FormBack === "W39F2011");
    };

    loadDataGrids = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { skip, limit } = this.filter;
        let params = {
            TransID: VoucherID || "",
            Language: Config.language || "84",
            Mode: 0,
            FormID: "W39F2000",
            skip,
            limit
        };
        this.setState({loading: true});
        this.props.w39F2000Actions.getDataGrids(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({loading: false});
                return false;
            }
            this.setState({
                dataGrid: data && data.rows ? data.rows : [],
                // dataGrid:  [{ApproveStatus:1,IsApproved:1,EvaluatedStatus:1,
                //     ApproveLevel:1,TransTypeName:"TransTypeName",WorkFormName:"WorkFormName",Note:"Note",EmployeeName:"EmployeeName"}],

                total: data && data.total ? data.total : 0,
            }, () => {
                const getKeyID = _.get(this.props, "location.state.data.keyID", false);

                this.setState({loading: false});
                if (getKeyID) {
                    const getDataByKeyID = _.filter(_.get(data, "rows", []), item => item.TransID === getKeyID);
                    this.onDbRowClick({data: _.get(getDataByKeyID, "[0]", {})})
                }
            });
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        // this.loadCboLeaveTypes();
        this.loadDataGrids();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {dataGrid} = this.state;
        Config.callChildForm({
            FormID: "W39F2000",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: dataGrid || [],
            onLoad: (params) => this.loadDataGrids(params && params.ID),
            onAction: (e, data) => this.onView({row: { data: data }})
        }, this.props);
    }

    onHistoryApproval = (e, data = {}) => {
        this.setState({w84F3005PopupData: {TransID: data.TransID}, showW84F3005Popup: true})
    }
    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid();
    };

    // onAdd = () => {
    //     browserHistory.push({
    //         pathname: Config.getRootPath() + "W39F1011",
    //         state: {
    //             mode: "add",
    //             FormID: "W39F2000",
    //             ApproveLevel: 0,
    //             TransID: "",
    //             EvaluationVoucherID: ""
    //         }
    //     });
    // };

    onView = (e) => {
        const {data} = e.row;
        const {EvaluatedStatus} = data;
        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1011",
            state: {
                Mode: EvaluatedStatus !== 0 ? 1 : 0,
                FormID: "W39F2000",
                ApproveLevel: data && data.ApproveLevel ? data.ApproveLevel : 0,
                TransID: data && data.TransID ? data.TransID : "",
                TransTypeID: data && data.TransTypeID ? data.TransTypeID : "",
                EvaluationVoucherID: data && data.EvaluationVoucherID ? data.EvaluationVoucherID : "",
                IsSave: data && (data.IsSave || data.IsSave === 0) ? data.IsSave : 1,
                UserLevel: data && data.UserLevel ? data.UserLevel : 1,
                EmployeeID: data && data.EmployeeID ? data.EmployeeID : "",
            }
        });
    };

    onDbRowClick = (e) => {
        if (!e) return false;
        const {data} = e;

        browserHistory.push({
            pathname: Config.getRootPath() + "W39F1011",
            state: {
                Mode: data?.EvaluatedStatus !== 0 ? 1 : 0,
                FormID: "W39F2000",
                ApproveLevel: data && data.ApproveLevel ? data.ApproveLevel : 0,
                TransID: data && data.TransID ? data.TransID : "",
                TransTypeID: data && data.TransTypeID ? data.TransTypeID : "",
                EvaluationVoucherID: data && data.EvaluationVoucherID ? data.EvaluationVoucherID : "",
                IsSave: data && (data.IsSave || data.IsSave === 0) ? data.IsSave : 1,
                UserLevel: data && data.UserLevel ? data.UserLevel : 1,
                EmployeeID: data && data.EmployeeID ? data.EmployeeID : "",
            }
        });
    };

    renderPicture = (e) => {
        const {data} = e.row;
        return (
            <div>
                <UserImage data={data}/>
            </div>
        );
    };

    renderItem = (e) => {
        const {data} = e.row;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div className={"w75f2000_info"}>
                    <div style={{fontSize: '1.12rem'}}><UserName data={data}/></div>
                    <div style={{fontWeight: 200}}>
                        {Config.convertDate(data.AssessDate, "", "DD/MM/YYYY, kk:mm:ss", true)}
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = (e) => {
        const {data} = e.row;
        const {classes} = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div>
                    {Config.lang("DHR_Loai_danh_gia")}:&nbsp;{data.TransTypeName || ""}
                </div>
                {data.WorkFormName && <div>
                    {Config.lang("DHR_Loai_hop_dong")}:&nbsp;{data.WorkFormName || ""}
                </div>}
                {data.Note && <div>
                    {Config.lang("DHR_Ghi_chu")}:&nbsp;{data.Note || ""}
                </div>}
            </div>
        );
    };

    renderAction = (e) => {
        const {data} = e.row;
        const {classes} = this.props;
        let status = data && data.EvaluatedStatus ? data.EvaluatedStatus / 2 : 0;
        const approvalStatus = data && Number(data.ApproveStatus);

        return (
            <div className={"display_row align-center"}>
                <IconButton aria-label="view" className={"mgr10"} size="small"
                            onClick={() => this.onHistoryApproval(e, data)}>
                    <HistoryIcon/>
                </IconButton>
                <IconButton aria-label="view" className={"mgr10"} size="small" onClick={() => this.onView(e)}>
                    <Rating
                        max={1}
                        value={status}
                        readOnly
                        size={"large"}
                        precision={0.5}
                        classes={{iconEmpty: classes.iconEmpty}}
                        emptyIcon={<StarBorderIcon fontSize="inherit"/>}
                    />
                </IconButton>
                {
                    data ?
                        approvalStatus !== 0 && approvalStatus !== 1 ?
                            <Rating
                                max={1}
                                value={1}
                                readOnly
                                size={"large"}
                                className={`mgr10 `}

                                classes={{root: classes.iconCheckRootError}}

                                icon={<Icon className="fas fa-times"/>}
                            />
                            : data.IsApproved ?
                            <Rating
                                max={1}
                                value={approvalStatus}
                                readOnly
                                size={"large"}
                                className={`mgr10 `}
                                classes={{root: classes.iconCheckRoot}}
                                icon={<Icon className="fas fa-check"/>}
                            />
                            : null
                        : null
                }

            </div>
        );
    };

    render() {
        let {iPermission, dataGrid, loading, total, showW84F3005Popup, w84F3005PopupData = {}} = this.state;
        if (!iPermission) return null;
        return (
            <React.Fragment>
                <W84F3005 open={showW84F3005Popup} onClose={e => this.setState({showW84F3005Popup: false})}
                          FormID="W39F2000" TransID={w84F3005PopupData.TransID}/>
                {/*<div className={"hidden"}>{this.renderFilters()}</div>*/}
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Nhan_vien_tu_danh_gia_het_HDLD")}
                    >
                        {/*<ButtonGeneral name={Config.lang('DHR_Them')}*/}
                        {/*    disabled={loading || !iPermission >= 2}*/}
                        {/*    typeButton={'add'}*/}
                        {/*    color={"primary"}*/}
                        {/*    variant={"custom"}*/}
                        {/*    style={{ textTransform: 'uppercase' }}*/}
                        {/*    size={"large"}*/}
                        {/*    onClick={this.onAdd} />*/}
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                style={{border: 'none'}}
                                dataSource={dataGrid}
                                totalItems={total}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid()}
                                typePaging={"remote"}
                                loading={loading}
                                showRowLines={false}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                noDataText={Config.lang("DHR_No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onDbRowClick={this.onDbRowClick}
                            >
                                <Column cellRender={this.renderPicture} width={70}/>
                                <Column
                                    dataField={'EmployeeName'}
                                    cellRender={this.renderItem}
                                    width={250}
                                    // width={300}
                                />
                                <Column cellRender={this.renderInfo}/>
                                <Column cellRender={this.renderAction} width={150}/>
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(null, (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w39F2000Actions: bindActionCreators(W39F2000Actions, dispatch)
})), withStyles(styles, {withTheme: true}))(W39F2000);
