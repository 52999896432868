/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 3/7/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const usePlanStyle = makeStyles((theme) => ({
    divGoalPlanCont: {
        display: 'flow-root',
        padding: (props) => (props.expand ? '0 0 18px' : '18px 0'),
        '&:hover': {
            background: (props) => (props.expand ? 'white' : '#eff5ff'),
        },
    },
    divGoalPlanName: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 5,
        marginBottom: (props) => (props.expand ? 15 : 0),
    },
}));
