/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI 
 * @create 31/12/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash";

export const useStyles = makeStyles((theme) => ({
    panel: {
        transition: 'all .4s'
    },
    panelForm: {
        opacity: 1,
        transition: 'all .5s',
        display: (props) => (props.minimum ? 'none' : '')
    },
    nowrap: {
        whiteSpace: 'nowrap'
    },
    nowrapSpan: {
        '& span': {
            whiteSpace: 'nowrap'
        }
    },
    hiddenOpacity: {
        opacity: 0
    },
    actionToolbar: {
        textTransform: 'uppercase !important',
        marginTop: '0px !important'
    },
    textUppercase: {
        textTransform: 'uppercase !important'
    },
    actionToolbarFilter: {
        marginTop: '0px !important',
        backgroundColor: (props) => (props.minimum ? theme.palette.primary.main : '#F7F9FC'),
        '& svg': {
            '& path': {
                fill: (props) => (props.minimum ? theme.palette.primary.contrastText : '')
            }
        },
        padding: '0 15px',
        marginBottom: 15,
        fontSize: '1rem'
    },
    flex: {
        display: 'flex'
    },
    alignCenter: {
        alignItems: 'center'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    gap10: {
        gap: 10
    },
    pointerCursor: {
        cursor: 'pointer'
    },
    justifyBetween: {
        justifyContent: 'space-between'
    },
    mgb15: {
        marginBottom: 15
    },
    leftCol: {
        overflow: 'hidden',
        width: (props) => (props.minimum ? '84px' : ''),
        [theme.breakpoints.down(768)]: {
            borderRight: 'none'
        }
    },
    rightCol: {
        width: (props) => (props.minimum ? 'calc(100% - 84px)' : ''),
        borderLeft: '1px solid #ddd'
    },
    justifyEnd: {
        justifyContent: 'flex-end'
    },
    mgr5: {
        marginRight: 5
    },
    mgb0: {
        marginBottom: 0
    },
    mg0: {
        margin: 0
    },
    orgchartLabel: {
        fontStyle: 'normal',
        fontSize: '12px',
        color: '#555868',
        fontFamily: `${_.get(theme, 'typography.fontFamily', '')}`,
        fontWeight: `${_.get(theme, 'typography.fontWeightMedium', '500')}`
    }
}));
